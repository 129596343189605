<template>
  <div>
    <nav class="navbar" id="navbar">
      <div
        class="container relative flex flex-wrap items-center justify-between"
      >
        <router-link to="/" class="navbar-brand md:me-8">
          <span class="inline-block dark:hidden">
            <img src="../assets/images/logo-dark.png" class="l-dark" alt="" />
            <img src="../assets/images/logo-light.png" class="l-light" alt="" />
          </span>
          <img
            src="../assets/images/logo-light.png"
            class="hidden dark:inline-block"
            alt=""
          />
        </router-link>

        <div class="nav-icons flex items-center lg_992:order-2 md:ms-8">
          <!-- Navbar Button -->
          <!-- <router-link to="/login">
                    <ul class="list-none menu-social mb-0">
                        <li class="inline">
                            <a href="#" class="size-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-blue-600 hover:bg-blue-800 border-blue-600 hover:border-blue-800 text-white"><i class="uil uil-user"></i></a>
                        </li>
                    </ul>
                </router-link> -->
          <!-- Navbar Collapse Manu Button -->
          <button
            data-collapse="menu-collapse"
            type="button"
            class="collapse-btn inline-flex items-center ms-2 text-dark dark:text-white lg_992:hidden"
            aria-controls="menu-collapse"
            aria-expanded="false"
            @click="handler"
          >
            <span class="sr-only">Navigation Menu</span>
            <i class="mdi mdi-menu text-[24px]"></i>
          </button>
        </div>

        <!-- Navbar Manu -->

        <div
          class="navigation lg_992:order-1 lg_992:flex ms-auto"
          :class="{ hidden: !toggle }"
          id="menu-collapse"
        >
          <ul class="navbar-nav nav-light" id="navbar-navlist">
            <li
              class="nav-item ms-0 home cursor-pointer active"
              @click="scrollToView('home')"
            >
              <span class="nav-link">Home</span>
              <!-- <a class="nav-link" href="#home">Home</a> -->
            </li>
            <li
              class="nav-item ms-0 features cursor-pointer"
              @click="scrollToView('features')"
            >
              <!-- <a class="nav-link" href="#features">Services</a>
                -->
              <span class="nav-link">Services</span>
            </li>
            <!-- <li class="nav-item ms-0 pricing">
                            <a class="nav-link" href="#pricing">Pricing</a>
                        </li>
                        <li class="nav-item ms-0 ">
                            <a class="nav-link" href="https://docs.astrowarp.net/en/tutorials/" target="_blank">Docs</a>
                        </li>-->
            <li
              class="nav-item ms-0 blog cursor-pointer"
              @click="scrollToView('blog')"
            >
              <!-- <a class="nav-link" href="#blog">Blog</a> -->
              <span class="nav-link">Blog</span>
            </li>

            <!-- <li class="nav-item ms-0">
              <a
                class="nav-link"
                href="https://docs.astrowarp.net/en/"
                target="_blank"
                >Docs</a
              >
            </li> -->
            <li class="nav-item ms-0 support" @mouseover="supportExpand = true" @mouseleave="supportExpand = false">
              <span class="nav-link"
                >Support
                <i v-if="supportExpand" class="fa fa-chevron-up"></i>
                <i v-else class="fa fa-chevron-down"></i>
              </span>
              <ul
                class="support-list"
                :class="supportExpand ? 'active' : ''"
                v-if="supportExpand"
                
              >
                <li><a target="_blank" href="https://docs.astrowarp.net/">Docs</a></li>
                <li><a target="_blank" href="https://forum.gl-inet.com/">Forum</a></li>
                <li><a target="_blank" href="https://discord.gg/SybeWJJ6Bs">Discord</a></li>
              </ul>
            </li>
            <li
              class="nav-item ms-0 contact cursor-pointer"
              @click="scrollToView('contact')"
            >
              <!-- <a class="nav-link" href="#contact">Contact us</a> -->
              <span class="nav-link">Contact us</span>
            </li>
            <li class="nav-item ms-0 log-in cursor-pointer">
              <span class="nav-link"
                ><a
                  href="https://my.astrowarp.net/"
                  class="log-a"
                  target="_blank"
                  >Log in</a
                >
                <a
                  href="https://my.astrowarp.net/#/signup"
                  class="sign-a"
                  target="_blank"
                  >Sign up</a
                >
              </span>
            </li>
          </ul>
          <!-- <div class="login_btn">
              <a href="https://my.astrowarp.net/" target="_blank">Log in</a>
            </div> -->
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
// This may come from a CMS, markdown file, etc.

export default {
  name: "navbarVue",
  components: {},
  data() {
    return {
      toggle: false,
      scroll: true,
      sections: document.querySelectorAll("section"),
      navLi: document.querySelectorAll("nav .container .navigation ul li"),
      current: "",
      sectionTop: null,
      supportExpand: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.onscroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.onscroll);
  },

  methods: {
    handler: function () {
      this.toggle = !this.toggle;
    },
    smoothScrollTo(element, duration) {
      if (duration <= 0) {
        element.scrollIntoView();
        return;
      }

      const y = element.getBoundingClientRect().top;
      const yCurrent = window.scrollY;
      const yDistance = y - yCurrent;
      const yStep = (yDistance / duration) * 16;

      const smoothScroll = () => {
        if (window.scrollY < y) {
          window.scrollBy(0, Math.min(yStep, y - window.scrollY));
          requestAnimationFrame(smoothScroll);
        }
      };

      smoothScroll();
    },

    scrollToView(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    handleScroll() {
      const navbar = document.getElementById("navbar");
      // console.log(event);
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("is-sticky");
      } else {
        navbar.classList.remove("is-sticky");
      }
    },

    onscroll() {
      document.querySelectorAll("section").forEach((section) => {
        this.sectionTop = section.offsetTop;

        if (pageYOffset >= this.sectionTop - 60) {
          this.current = section.getAttribute("id");
        }
        // 判断滚动到底部
        var scrollHeight = document.documentElement.scrollHeight;
        var scrollTop = document.documentElement.scrollTop;
        var clientHeight = document.documentElement.clientHeight;

        if (scrollHeight - (scrollTop + clientHeight) < 1) {
          // 滚动到了底部
          this.current = "contact";
        }
      });
      document
        .querySelectorAll("nav .container .navigation ul li")
        .forEach((li) => {
          if (li.classList.contains(this.current)) {
            li.classList.add("active");
          } else {
            li.classList.remove("active");
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.login_btn {
  color: #fff;
  position: absolute;
  right: 0;
  /* top: 6px; */
  /* line-height: 48px; */
  // border: 2px solid #fff;
  border-radius: 35px;
  // padding: 4px 18px;
  margin-top: 10px;
}
.login_btn:hover {
  text-decoration: underline;
}
.is-sticky .login_btn {
  color: #000;
  // border: 2px solid #000;
}
.log-in {
  a {
    padding: 0 16px;
    border: 1px solid #7c3aed;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    display: inline-block;
    height: 30px;
    line-height: 29px;
    letter-spacing: 0.5px;
    background: #7c3aed;
    color: #fff;
    position: relative;
    overflow: hidden;
    font-weight: 700;
    font-size: 12px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .log-a {
    border: 1px solid #7c3aed;
    background: #fff;
    color: #7c3aed;
    margin-right: 10px;
  }
}
.support {
  position: relative;
  cursor: pointer;
}
.support-list {
  background-color: #fff;
  color: #333150;
  padding: 4px 8px;
  border-radius: 4px;
  left: 10px;
  position: absolute;
  width: 120px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  top: 74px;
  opacity: 0;
  transform: translateY(-2em);
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  li {
    margin: 0;
    display: block;
    background: #fff;
    color: #333150;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    font-size: 13px;
    border-bottom: 1px solid #f5f5f5;
  }
  li:hover {
    text-decoration: underline;
  }
}
.active {
  opacity: 1;
  z-index: 1000;
  visibility: visible;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
  z-index 0s linear 0.01s;
}
.is-sticky {
  .support-list {
    border: 1px solid #7c3aed;
  }
}
</style>