<template>
  <div class="hello">
    <NavbarVue />
    <!-- Hero Start -->
    <section
      class="py-36 lg:py-56 w-full table relative bg-[url('../../assets/images/bg/bg-galaxy.jpg')] bg-center bg-no-repeat"
      style="background-size: cover;"
      id="home"
    >
      <div class="absolute inset-0 bg-slate-950/60"></div>
      <div class="container">
        <div class="grid grid-cols-1 pb-8 mt-10">
          <h3
            class="font-medium lg:leading-normal leading-normal lg:text-[40px] text-4xl mb-5 mt-10 text-white"
          >
          Join the AstroWarp community today! 
          </h3>

          <p class="text-lg font-medium text-white/60">
            By participating in our survey, you’ll have a chance to receive a free trial of AstroWarp.<br/>
             Don’t miss this opportunity to be part of our growing community!
          </p>

          <div class="subcribe-form mt-6">
            <button
                type="submit"
                name="subscribe"

                class=" relative py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-blue-600 hover:bg-blue-700 border-blue-600 hover:border-blue-700 text-white rounded-full absolute h-11 top-0.5 end-0.5"
              >
               <a href="https://link.gl-inet.com/astrowarp-beta-form-240903" target="_blank"> Join Now </a><i class="uil uil-arrow-right"></i>
              </button>
            <!-- <form
              class="relative max-w-xl"
              action="https://gl-inet.us16.list-manage.com/subscribe/post?u=8ed66b6ad326f7c2f2f6127f4&amp;id=b48dc644fc&amp;f_id=003c7de0f0"
              method="post"
           
              target="_blank"
              name="mc-embedded-subscribe-form"
            >
              <input
                class="rounded-full bg-white/90 h-12 w-full py-3 ps-5 pe-40 text-slate-950 dark:text-white"
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                placeholder="Email Address"
                required=""
                value=""
              />
              <button
                type="submit"
                name="subscribe"

                class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-blue-600 hover:bg-blue-700 border-blue-600 hover:border-blue-700 text-white rounded-full absolute h-11 top-0.5 end-0.5"
              >
                Join Now <i class="uil uil-arrow-right"></i>
              </button>
            </form> -->
            <!--end form-->
          </div>
        </div>
        <!--end grid-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div
      :class="isActive ? 'fixed' : 'hidden'"
      class="bg-black/[0.9] top-0 left-0 bottom-0 w-[100%] h-[100%] z-999"
    >
      <div class="h-[100%] flex items-center justify-center">
        <div class="relative inline-block">
          <iframe
            src="https://www.youtube.com/embed/S_CGed6E610"
            width="700"
            height="500"
            frameborder="0"
          ></iframe>
          <a
            class="mdi mdi-close text-white absolute top-1 right-1 text-xl"
            @click="toggle"
          ></a>
        </div>
      </div>
    </div>
    <!-- Hero End -->
    <!-- Hero End -->
    <AboutVue />
    <featuresVue :isHome="true" />
    <cta />
    <Blogs />
    <!-- <pricing /> -->

    <GetInTouch />
    <Footer />
  </div>
</template>
  
<script>
import NavbarVue from "../components/NavbarComponent.vue";
import AboutVue from "../components/AboutComponent.vue";
import featuresVue from "../components/FeaturesComponent.vue";
import cta from "../components/Cta.vue";
import Blogs from "../components/BlogComponent.vue";
// import pricing from "../components/PricingComponent.vue";
import GetInTouch from "@/components/GetInTouchComponent.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "IndexPage",
  data() {
    return {
      isActive: false,
    };
  },
  components: {
    NavbarVue,
    AboutVue,
    featuresVue,
    cta,
    // pricing,
    Blogs,

    GetInTouch,
    Footer,
  },

  methods: {
    toggle() {
      if (!this.isActive) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
  },
};
</script>
  
<style scoped></style>
  